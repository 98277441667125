<template>
  <b-dropdown class="w-100" right text="Adicionar nova etapa" variant="primary" menu-class="w-100">
    <b-dropdown-item v-for="(opt, index) in options" :key="index" @click="addStage(opt.value)">
      {{ opt.label }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('template', ['enabledTypes']),
    prefix: () => 'sparkfunnels.templates.create.select_stage_button',
    options() {
      return [
        {
          label: this.$t(`${this.prefix}.options.default`),
          value: { name: this.$t(`${this.prefix}.options.default`), stageType: this.enabledTypes.PAGE },
        },
        {
          label: this.$t(`${this.prefix}.options.checkout`),
          value: { name: this.$t(`${this.prefix}.options.checkout`), stageType: this.enabledTypes.CHECKOUT },
        },
        {
          label: this.$t(`${this.prefix}.options.webinar`),
          value: { name: this.$t(`${this.prefix}.options.webinar`), stageType: this.enabledTypes.WEBINAR },
        },
      ];
    },
  },
  methods: {
    ...mapMutations('template', ['addStage']),
  },
};
</script>

<style lang="scss" scoped>
/deep/.dropdown-toggle {
  background-color: $cyan !important;
}
</style>
