<template>
  <div v-if="openedStage" class="h-100 ">
    <div class=" position-relative px-4 py-5">
      <h3 class="lh-125 font-weight-bold mb-5">{{ openedStage.name }}</h3>
      <Theme v-if="pagesPreview" />
      <Emails :class="{ 'fix-email': !!pagesPreview }" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Emails from './Emails';
import Theme from './Theme';

export default {
  components: { Emails, Theme },
  computed: {
    ...mapGetters('template', ['openedStage', 'pagesPreview']),
  },
  methods: {
    ...mapActions('jwt', ['load']),
    ...mapGetters('jwt', ['isLoaded']),
    loadJwtToken() {
      if (!this.isLoaded()) this.load();
    },
  },
  created() {
    this.loadJwtToken();
  },
};
</script>

<style lang="scss" scoped>
.fix-email {
  padding-top: 267px;
}
</style>
