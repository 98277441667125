<template>
  <div class="image-container position-absolute w-100">
    <div class="theme-image w-100" :style="backgroundImage" />
    <hr class="divider mt-5" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('template', ['pagesPreview']),
    prefix: () => 'sparkfunnels.templates.create.information',
    backgroundImage() {
      return { 'background-image': `url(${this.pagesPreview})` };
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container,
.theme-image {
  height: 195px;
  left: 0;
}
.theme-image {
  background-size: cover;
}
.divider {
  left: 0;
}
</style>
