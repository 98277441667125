<template>
  <div class="tlw-flex tlw-flex-col tlw-px-2 tlw-pt-12 md:tlw-p-4 lg:tlw-p-12 tlw-w-full">
    <div class="tlw-flex tlw-flex-col sm:tlw-flex-row tlw-items-center tlw-justify-between tlw-mb-12">
      <h3 class="tlw-text-base tlw-font-bold tlw-mb-2 tlw-text-center md:tlw-text-left">
        {{ $t(`${prefix}.title`) }}
      </h3>

      <hs-button class="custom-button tlw-relative" variant="primary" :to="{ name: 'TemplateCreate' }">
        {{ $t(`${prefix}.create`) }}
      </hs-button>
    </div>

    <hs-loading v-if="isLoading" />
    <div v-else class="cards-wrapper">
      <TemplateCard v-for="template in templates" :key="template.id" :template="template" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TemplateCard from './components/Card';
import { hsLoading } from '@/components';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    TemplateCard,
    hsLoading,
  },
  computed: {
    ...mapState('funnel', ['templates']),
    prefix: () => 'sparkfunnels.templates.list',
  },
  methods: {
    ...mapActions('funnel', ['getFunnelTemplates']),
  },
  created() {
    this.isLoading = true;
    this.getFunnelTemplates({ published: null }).finally(() => (this.isLoading = false));
  },
};
</script>

<style lang="scss" scoped>
.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
