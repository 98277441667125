<template>
  <div class="area h-100 px-4 py-5">
    <h3 class="lh-125 font-weight-bold">{{ $t(`${prefix}.view_of_your_funnel`) }}</h3>
    <p class="subtitle lh-150 mt-3 mb-0">{{ $t(`${prefix}.track_your_settings`) }}</p>
    <CustomCard />
  </div>
</template>

<script>
import CustomCard from './Card';

export default {
  components: { CustomCard },
  computed: { prefix: () => 'sparkfunnels.templates.create.information' },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: $grey-30;
}

.area {
  background-image: url('~@/assets/images/SparkFunnels/Funnels/Templates/area.svg');
  background-repeat: no-repeat;
  background-position: 100% 85%;
}
</style>
