<template>
  <div class="w-100 d-flex justify-content-start">
    <div class="d-flex align-items-center justify-content-center flex-column mr-4">
      <div class="divider-top pt-3" />
      <div class="index rounded-circle d-flex align-items-center justify-content-center font-weight-bold lh-150">
        {{ index }}
      </div>
      <div class="divider flex-1 h-100" />
    </div>
    <div class="w-100 min-w-0 mb-2">
      <b-button
        id="collapse-btn"
        block
        v-b-toggle="id"
        role="tab"
        class="btn-collapse h6 px-4 py-4 py-lg-3 mb-0 text-left d-flex justify-content-between align-items-center position-relative"
      >
        <div class="align-items-center d-flex">
          <slot name="icon" />
          <h5 class="pr-2 pr-md-0 font-weight-bold text-dark text-truncate lh-150">{{ title }}</h5>
        </div>
        <div class="d-flex align-items-center">
          <hs-button @click.stop="$emit('on-delete-stage')" icon="trash-alt" class="mr-3" variant="transparent" />
          <hs-icon class="icon text-dark" variant="light" icon="chevron-up" v-bind:class="{ collapsed }" />
        </div>
      </b-button>
      <b-collapse :id="id" accordion="custom-accordion" role="tabpanel" v-bind="$attrs" v-on="$listeners">
        <div class="bg-white ml-sm-3 p-4 pb-sm-0">
          <slot name="content" />
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    index: {
      required: true,
      type: Number,
      default: 1,
    },
    title: {
      required: true,
      type: String,
    },
  },
  computed: {
    id() {
      return this._uid.toString();
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 20px;
  height: 20px;
  background: $white;
  @media only screen and (min-width: $screen-bs-sm) {
    background: $light-blue;
  }
  box-shadow: 0px 2px 4px rgba($cyan, 0.2);
  font-size: 14px;
}

.icon {
  transition: 0.3s transform ease-in-out;
}

.collapsed .icon {
  transform: rotate(180deg);
}

.divider,
.divider-top {
  border-left: 1px solid $grey-25;
}

.min-w-0 {
  min-width: 0;
}

.btn-collapse {
  color: $grey-30;
  background: $light-blue;
  border-radius: 4px 4px 0px 0px !important;
  box-shadow: 0px 2px 4px rgba($cyan, 0.2);
  &:active {
    background-color: $light-blue !important;
  }

  &:active:focus {
    box-shadow: 0px 2px 4px rgba($cyan, 0.2) !important;
  }
}
</style>
