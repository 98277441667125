<template>
  <div class="card-wrapper sm:tlw-mr-4 tlw-mb-4">
    <div
      class="blob-bg tlw-shadow-sm tlw-rounded tlw-mt-6 tlw-bg-white tlw-pt-4 tlw-px-4 tlw-flex tlw-flex-col tlw-break-words"
    >
      <b-img-lazy :src="illustration" class="tlw-mx-auto tlw-mb-6 tlw--mt-12 tlw-w-1/2" />
      <div v-if="template.funnel_tags.length" data-testid="card-tag-section" class="tlw--mt-6 tlw--ml-1">
        <div
          v-for="tag in template.funnel_tags"
          :key="tag.id"
          data-testid="card-tag"
          class="tlw-inline-block tlw-bg-purple-500 tlw-text-xs tlw-self-start tlw-rounded-full tlw-text-white tlw-font-bold tlw-px-2 tlw-py-1 tlw-mb-1 tlw-mr-2"
        >
          {{ tag.name }}
        </div>
      </div>
      <h5 data-testid="card-title" class="tlw-mb-1 tlw-font-bold tlw-mt-2">{{ template.title }}</h5>
      <p data-testid="card-description" class="tlw-text-gray-600 tlw-text-sm tlw-leading-6">{{ template.subtitle }}</p>

      <hs-button
        v-b-toggle="`collapse-${template.id}`"
        variant="link"
        class="show-more-button tlw-self-end tlw-mt-auto"
      >
        {{ $t(`${prefix}.${visible ? 'see_less' : 'see_more'}`) }}
        <hs-icon class="tlw-ml-2" :icon="`chevron-${visible ? 'up' : 'down'}`" size="20" />
      </hs-button>
    </div>
    <b-collapse :id="`collapse-${template.id}`" v-model="visible" class="tlw-mb-2">
      <div
        :style="getCardBgStyle(template.funnel_stages.length)"
        class="tlw-shadow-sm tlw-rounded tlw-bg-white tlw-py-6 tlw-px-0 tlw-flex tlw-flex-col tlw-break-words"
      >
        <ol class="stages-list tlw-mb-12 tlw-ml-4 tlw-pl-4">
          <template>
            <div v-for="(stage, index) in template.funnel_stages" :key="stage.id">
              <p
                v-if="index === 0"
                class="tlw--ml-4 tlw-text-xxs tlw-text-gray-500 tlw-leading-6 tlw-mb-1 tlw-uppercase"
              >
                {{ $t(`${prefix}.first_stage_info`) }}
              </p>
              <p
                v-if="stage.stage_type.match(/checkout/gi)"
                class="tlw--ml-4 tlw-text-xxs tlw-text-gray-500 tlw-leading-6 tlw-mb-1 tlw-uppercase"
              >
                {{ $t(`${prefix}.checkout_stage_info`) }}
              </p>
              <li class="tlw-text-sm tlw-leading-6 tlw-font-bold">
                <span>{{ stage.name }}</span>
                <p class="tlw-font-normal tlw-text-gray-600 tlw-mb-6">
                  {{ stage.objective }}
                </p>
              </li>
            </div>
          </template>
        </ol>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: this.opened,
    };
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCardBgStyle(length) {
      let bgList = [];

      for (let i = 0; i < length; i++) {
        bgList.push(this.segmentsBgList[i]);
      }

      bgList.push(this.blobShapeBg);

      return {
        backgroundImage: bgList.map(seg => seg.path).join(','),
        backgroundPosition: bgList.map(seg => seg.position).join(','),
        backgroundRepeat: 'no-repeat',
      };
    },
  },
  computed: {
    illustration() {
      const DefaultFunnelIllustration = require('@/assets/images/SparkFunnels/Funnels/Create/default-funnel-illustration.svg');

      if (this.template.media) {
        const illustration = this.template.media.find(media => media.image_type === 'illustration');

        return illustration ? illustration.url : DefaultFunnelIllustration;
      }
      return DefaultFunnelIllustration;
    },
    prefix() {
      return 'sparkfunnels.funnels.create.steps.template_selection.card';
    },
    segmentsBgList() {
      return [
        {
          path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_1.svg')})`,
          position: 'right 20px',
        },
        {
          path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_2.svg')})`,
          position: 'right 125px',
        },
        {
          path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_3.svg')})`,
          position: 'right 200px',
        },
        {
          path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_4.svg')})`,
          position: 'right 265px',
        },
        {
          path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_5.svg')})`,
          position: 'right 315px',
        },
        {
          path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_6.svg')})`,
          position: 'right 360px',
        },
      ];
    },
    blobShapeBg() {
      return {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/blob_shape_bottom.svg')})`,
        position: 'right top',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  min-width: 250px !important;
  max-width: 330px !important;
}

.show-more-button {
  color: $cyan;
}

.blob-bg {
  background-image: url('~@/assets/images/SparkFunnels/Funnels/Create/blob_shape_top.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media only screen and (min-width: $screen-bs-sm) {
  #select-btn {
    width: auto !important;
  }
}

[id^='collapse'] {
  border-top: 1px solid $grey-17;
}

.stages-list {
  max-width: 200px;
}
</style>
