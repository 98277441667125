<template>
  <div class="save-card mt-4 d-flex">
    <b-img-lazy src="@/assets/images/SparkFunnels/Funnels/Templates/save-card.svg" :alt="$t(`${prefix}.alt_img`)" />
    <div class="pl-2 py-4 d-flex flex-column justify-content-between align-items-start">
      <h5 class="lh-150 font-weight-bold">{{ $t(`${prefix}.description`) }}</h5>
      <hs-button @click="save" variant="primary" :disabled="isSaving || !isValid">
        <b-spinner small type="grow" v-if="isSaving" />
        {{ $t(`${prefix}.button`) }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import ToastHelper from '@/shared/helpers/toast';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters('template', ['payloadMounted']),
    ...mapState('template', ['stages']),
    prefix: () => 'sparkfunnels.templates.create.save_card',
    isValid() {
      return this.stages.length;
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    ...mapActions('template', ['createTemplate']),
    save() {
      this.isSaving = true;
      this.createTemplate(this.payloadMounted)
        .then(() => ToastHelper.successMessage(this.$t(`${this.prefix}.success_create_template`)))
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.error_create_template`)))
        .finally(() => (this.isSaving = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.save-card {
  background-color: $pale-cyan;
}
</style>
