<template>
  <div>
    <draggable v-model="myList" handle=".handle" @end="dropped" role="tablist">
      <CustomCollapse
        class="custom-collapse w-100 d-flex position-relative"
        v-for="(stage, index) in myList"
        @on-delete-stage="onDeleteStage(stage)"
        :key="`sortable-collapse-${index}`"
        :index="index + 1"
        :title="stage.name"
        @show="setStageOpened(index)"
      >
        <template slot="icon">
          <hs-icon variant="light" icon="bars" class="handle text-dark mr-3" />
        </template>
        <template slot="content">
          <Stage :stage-id="stage.id" />
          <Emails v-if="index !== 0" :stage="stageById(stage.id)" />
        </template>
      </CustomCollapse>
    </draggable>
    <hsConfirmModal
      id="delete-stage-modal"
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t('sparkfunnels.templates.create.sortable_collapse.confirm_delete.title')"
      :description="$t('sparkfunnels.templates.create.sortable_collapse.confirm_delete.description')"
      :ok-title="$t('sparkfunnels.templates.create.sortable_collapse.confirm_delete.ok_title')"
      :cancel-title="$t('sparkfunnels.templates.create.sortable_collapse.confirm_delete.cancel_title')"
      @ok="deleteStage(selectedStage.id)"
    />
  </div>
</template>

<script>
import CustomCollapse from '../components/CustomCollapse';
import { hsConfirmModal } from '@/components';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Stage from './Stage';
import Emails from './Emails';
import Confirm from '@/shared/mixins/Confirm';

export default {
  components: { CustomCollapse, draggable, hsConfirmModal, Stage, Emails },
  mixins: [Confirm],
  data() {
    return {
      selectedStage: 0,
    };
  },
  computed: {
    ...mapState('template', ['stages']),
    ...mapGetters('template', ['stageById']),
    myList: {
      get() {
        return this.stages;
      },
      set(data) {
        this.setStages(data);
      },
    },
  },
  methods: {
    ...mapMutations('template', ['setStages', 'moveStage', 'deleteEmails', 'setStageOpened']),
    ...mapActions('template', ['deleteStage']),
    onDeleteStage(stage) {
      this.selectedStage = stage;
      this.$bvModal.show('delete-stage-modal');
    },
    async dropped({ newIndex, oldIndex }) {
      if (newIndex === 0) {
        const isSure = await this.showConfirmBox({
          contentTitle: this.$t('sparkfunnels.templates.create.sortable_collapse.confirm_move.title'),
          contentDescription: this.$t('sparkfunnels.templates.create.sortable_collapse.confirm_move.description'),
          okTitle: this.$t('sparkfunnels.templates.create.sortable_collapse.confirm_move.ok_title'),
          cancelTitle: this.$t('sparkfunnels.templates.create.sortable_collapse.confirm_move.cancel_title'),
          variant: 'cherry',
          icon: 'arrows-alt',
        });

        if (isSure) {
          this.deleteEmails(this.stages[0].id);
        } else {
          this.moveStage({ newIndex: oldIndex, oldIndex: newIndex });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.handle {
  cursor: grabbing;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/deep/.custom-collapse {
  &:first-child {
    .divider-top {
      border: none;
    }
  }
  &:last-child {
    .divider {
      border: none;
    }
  }
}
</style>
