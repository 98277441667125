<template>
  <div class="d-flex">
    <div class="w-100 mt-lg-2 p-5">
      <h3 class="lh-125 font-weight-bold">{{ $t(`${prefix}.title`) }}</h3>
      <p class="subtitle lh-150 mt-3">{{ $t(`${prefix}.subtitle`) }}</p>
      <main class="mt-5 pt-4 position-relative">
        <b-tabs
          v-model="tabIndex"
          nav-class="border-0 flex-nowrap mw-100 mb-2"
          active-nav-item-class="active-tab bg-transparent border-0"
        >
          <b-tab
            v-for="tab in tabs"
            title-link-class="tab-item lh-150 font-weight-bold pb-1 pl-0 pr-1 mr-3 text-uppercase"
            :title="tab.title"
            :key="tab.id"
            lazy
          >
            <component v-bind:is="tab.component" />
          </b-tab>
        </b-tabs>
        <div class="stage-option pt-4 position-absolute" v-if="isStagesTab">
          <SelectStageButton />
        </div>
        <SaveCard />
      </main>
    </div>
    <div class="funnel-information h-100 bg-white"><Information /></div>
  </div>
</template>

<script>
import GeneralConfiguration from './GeneralConfiguration';
import Stages from './SortableCollapse';
import Information from './components/Information';
import SaveCard from './components/SaveCard';
import SelectStageButton from './components/SelectStageButton';
import { mapMutations } from 'vuex';

export default {
  components: { Information, SaveCard, SelectStageButton },
  beforeDestroy() {
    this.resetState();
  },
  computed: {
    prefix: () => 'sparkfunnels.templates.create',
    tabIndex: {
      get() {
        return this.$store.state.template.tabIndex;
      },
      set(tabIndex) {
        this.setTabIndex(tabIndex);
      },
    },
    isStagesTab() {
      return this.tabIndex === 1;
    },
    tabs() {
      return [
        {
          id: 0,
          component: GeneralConfiguration,
          title: this.$t(`${this.prefix}.tabs.general_configuration`),
        },
        {
          id: 1,
          component: Stages,
          title: this.$t(`${this.prefix}.tabs.pages_and_emails`),
        },
      ];
    },
  },
  methods: {
    ...mapMutations('template', ['setTabIndex', 'resetState']),
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: $grey-30;
}

/deep/.active-tab {
  -webkit-box-shadow: 0px 3px 0px 0px $cyan;
  -moz-box-shadow: 0px 3px 0px 0px $cyan;
  box-shadow: 0px 3px 0px 0px $cyan;
  color: $grey-25 !important;
}

/deep/.tab-item {
  font-size: 12px;
  color: $grey-25;

  &:hover {
    color: $grey-25;
    border-color: transparent !important;
  }
}

/deep/.nav-tabs {
  white-space: nowrap;
  overflow: auto hidden;
  min-height: 40px;
}

.stage-option {
  right: 0;
  top: 0;
  min-width: 231px;
}

.funnel-information {
  min-width: 332px;
}
</style>
