<template>
  <div class="h-100">
    <GeneralConfig v-if="tabIndex === 0" />
    <Stage v-else />
  </div>
</template>

<script>
import GeneralConfig from './GeneralConfig';
import Stage from './Stage';

import { mapState } from 'vuex';

export default {
  components: { GeneralConfig, Stage },
  computed: {
    ...mapState('template', ['tabIndex']),
  },
};
</script>
