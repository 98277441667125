<template>
  <div class="d-flex flex-column w-100">
    <div class="configurations-header pl-4 py-3 rounded-top shadow">
      <p class="mb-0 font-weight-bold">{{ $t(`${prefix}.title`) }}</p>
    </div>
    <section class="bg-white ml-2 px-4 py-5">
      <div class="w-100 d-flex mb-5 align-items-start">
        <div>
          <label for="image-uploader" class="custom-label d-block mb-2 font-weight-bold">{{
            $t(`${prefix}.form.image-uploader.label`)
          }}</label>
          <hsImageUploader
            id="image-uploader"
            height="150"
            width="150"
            :image="illustration"
            :multiple="false"
            @onFile="onFile"
            @onClose="resetIllustration"
            class="mr-5"
            :acceptedFormats="[/image\/*/gi]"
          >
            <template>
              <div class="font-size-xs text-center mb-1">
                <p class="mb-2">{{ $t('messages.views.subject.index.inputs.image-input.uploader.label') }}</p>
                <hs-icon class="font-size-lg text-primary" variant="light" icon="cloud-upload" />
              </div>
            </template>
          </hsImageUploader>
        </div>
        <div class="flex-2">
          <hs-group class="mb-4" :label="$t(`${prefix}.form.title.label`)" label-for="title">
            <hs-input
              id="title"
              v-model="title"
              :state="!$v.title.$error ? null : false"
              @blur="$v.title.$touch()"
              :placeholder="$t(`${prefix}.form.title.placeholder`)"
            />
            <hs-form-invalid-feedback :state="!$v.title.$error">
              <span class="font-size-xs" v-if="!$v.title.required">
                {{ $t(`${prefix}.form.title.validations.required`) }}
              </span>
            </hs-form-invalid-feedback>
          </hs-group>
          <hs-group
            class="mb-0"
            :label="$t(`${prefix}.form.subtitle.label`)"
            label-for="subtitle"
            :description="!$v.subtitle.$error ? $t(`${prefix}.form.subtitle.hint`, { max: 140 }) : undefined"
          >
            <b-form-textarea
              id="subtitle"
              v-model="subtitle"
              rows="4"
              :state="!$v.subtitle.$error ? null : false"
              @blur="$v.subtitle.$touch()"
              :placeholder="$t(`${prefix}.form.subtitle.placeholder`)"
            />
            <hs-form-invalid-feedback :state="!$v.subtitle.$error">
              <span class="font-size-xs" v-if="!$v.subtitle.required">
                {{ $t(`${prefix}.form.subtitle.validations.required`) }}
              </span>
            </hs-form-invalid-feedback>
          </hs-group>
        </div>
      </div>
      <div class="mb-5">
        <b-form-group :label="$t(`${prefix}.form.objectives.label`, { max: 2 })">
          <RoundedCheckbox
            index-prop="value"
            :options="objectiveOptions"
            :value="objectives"
            class="mt-2"
            @input="setObjectives($event)"
          />
        </b-form-group>
      </div>
      <hs-group :label="$t(`${prefix}.form.language.label`)" label-for="language">
        <hs-multiselect
          id="language"
          v-model="language"
          :options="languages"
          label="text"
          trackBy="value"
          :placeholder="$t(`${prefix}.form.language.placeholder`)"
          selectLabel=""
          deselectLabel=""
        />
        <hs-form-invalid-feedback :state="!$v.language.$error">
          <span class="font-size-xs" v-if="!$v.language.required">
            {{ $t(`${prefix}.form.language.validations.required`) }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
    </section>
  </div>
</template>

<script>
import { hsImageUploader } from '@/components';
import RoundedCheckbox from '@/sparkfunnels/components/RoundedCheckbox';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';
import { uploadService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    RoundedCheckbox,
    hsImageUploader,
  },
  methods: {
    ...mapMutations('template', [
      'setLanguage',
      'setObjectives',
      'setSubtitle',
      'setTitle',
      'setIllustration',
      'resetIllustration',
    ]),
    async onFile(file) {
      try {
        const { data } = await uploadService.getAwsKeysByBucket({
          contentType: file.type,
          bucket: this.s3Config.bucket,
          key: this.s3Config.key,
        });
        this.illustration = await uploadService.upload(data.url, data.fields, file);
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefix}.actions.illustration_upload.error`));
      }
    },
  },
  computed: {
    ...mapGetters('funnel', ['getFunnelObjectives']),
    objectives: {
      get() {
        return this.$store.state.template.objectives;
      },
      set(objectives) {
        this.setObjectives(objectives);
      },
    },
    language: {
      get() {
        return this.languages.find(lang => lang.value === this.$store.state.template.language);
      },
      set({ value }) {
        this.setLanguage(value);
      },
    },
    title: {
      get() {
        return this.$store.state.template.title;
      },
      set(value) {
        this.setTitle(value);
      },
    },
    subtitle: {
      get() {
        return this.$store.state.template.subtitle;
      },
      set(value) {
        this.setSubtitle(value);
      },
    },
    illustration: {
      get() {
        return this.$store.state.template.illustration;
      },
      set(value) {
        this.setIllustration(value);
      },
    },
    prefix() {
      return 'sparkfunnels.templates.create.general_configuration';
    },
    objectiveOptions() {
      return [
        {
          value: this.getFunnelObjectives.LEAD_CAPTURE,
          icon: 'users',
          text: this.$t(`${this.prefix}.objectives.lead_capture`),
        },
        {
          value: this.getFunnelObjectives.MAKE_LAUNCH,
          icon: 'paper-plane',
          text: this.$t(`${this.prefix}.objectives.make_launch`),
        },
        {
          value: this.getFunnelObjectives.SELL_SOMETHING,
          icon: 'usd-circle',
          text: this.$t(`${this.prefix}.objectives.sell_something`),
        },
        {
          value: this.getFunnelObjectives.BROADCAST_WEBINAR,
          icon: 'tv',
          text: this.$t(`${this.prefix}.objectives.broadcast_webinar`),
        },
        {
          value: this.getFunnelObjectives.DONT_KNOW,
          icon: 'question-circle',
          text: this.$t(`${this.prefix}.objectives.dont_know`),
        },
      ];
    },
    languages() {
      return [
        {
          value: 'pt-BR',
          text: this.$t(`${this.prefix}.languages.portuguese`),
        },
        {
          value: 'en',
          text: this.$t(`${this.prefix}.languages.english`),
        },
        {
          value: 'es',
          text: this.$t(`${this.prefix}.languages.spanish`),
        },
      ];
    },
    s3Config() {
      return {
        key: `general/illustrations`,
        bucket: process.env.VUE_APP_SPARKFUNNELS_ASSETS_BUCKET,
      };
    },
  },
  validations: {
    title: {
      required,
    },
    subtitle: {
      required,
      maxLength: maxLength(140),
    },
    language: {
      required,
    },
    objectives: {
      required,
      maxLength: maxLength(2),
    },
  },
};
</script>

<style lang="scss" scoped>
.configurations-header {
  background-color: $light-blue;
  z-index: 1;
}

.custom-label {
  font-size: 12px;
}

/deep/ .form-group {
  > label,
  legend {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

/deep/ .option-info {
  background-color: $light-blue !important;
}
</style>
