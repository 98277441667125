<template>
  <div class="card-wrapper pt-2">
    <b-card body-class="blob-bg px-3 pt-3 pb-0 d-flex flex-column" class="header-border shadow-sm mt-5">
      <h5 class="mb-1 font-weight-bold mt-2">{{ title }}</h5>
      <p class="description h6 lh-150">{{ subtitle }}</p>
      <hs-button v-b-toggle="'custom-collapse'" variant="link" class="text-primary align-self-end mt-auto">
        {{ $t(`${prefix}.${visible ? 'see_less' : 'see_more'}`) }}
        <hs-icon class="ml-2" :icon="`chevron-${visible ? 'up' : 'down'}`" size="20" />
      </hs-button>
    </b-card>
    <b-collapse id="custom-collapse" v-model="visible" class="content-border mb-2 shadow-sm">
      <b-card :style="getCardBgStyle(stages.length)" body-class="py-4 px-0 d-flex flex-column">
        <ol class="stages-list mb-5 ml-3 pl-3">
          <template>
            <div v-for="(stage, index) in stages" :key="stage.id">
              <p v-if="index === 0" class="stage-info lh-150 mb-1 text-uppercase">
                {{ $t(`${prefix}.first_stage_info`) }}
              </p>
              <p v-if="stage.stageType.match(/checkout/gi)" class="stage-info lh-150 mb-1 text-uppercase">
                {{ $t(`${prefix}.checkout_stage_info`) }}
              </p>
              <li class="h6 lh-150 font-weight-bold">
                <span>{{ stage.name }}</span>
                <p class="font-weight-normal mb-4">
                  {{ stage.objective }}
                </p>
              </li>
            </div>
          </template>
        </ol>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    getCardBgStyle(length) {
      let bgList = [];

      for (let i = 0; i < length; i++) {
        bgList.push(this.segmentsBgList[i] || this.segmentsBgList[this.segmentsBgList.length - 1]);
      }

      bgList.push(this.blobShapeBg);

      return {
        backgroundImage: bgList.map(seg => seg.path).join(','),
        backgroundPosition: bgList.map(seg => seg.position).join(','),
        backgroundRepeat: 'no-repeat',
      };
    },
  },
  computed: {
    ...mapState('template', ['title', 'subtitle', 'stages']),
    prefix: () => 'sparkfunnels.templates.create.information.card',
    segmentsBgList: () => [
      {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_1.svg')})`,
        position: 'right 20px',
      },
      {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_2.svg')})`,
        position: 'right 125px',
      },
      {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_3.svg')})`,
        position: 'right 200px',
      },
      {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_4.svg')})`,
        position: 'right 265px',
      },
      {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_5.svg')})`,
        position: 'right 315px',
      },
      {
        path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/segment_6.svg')})`,
        position: 'right 360px',
      },
    ],
    blobShapeBg: () => ({
      path: `url(${require('@/assets/images/SparkFunnels/Funnels/Create/blob_shape_bottom.svg')})`,
      position: 'right top',
    }),
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  min-width: 250px !important;
  max-width: 330px !important;
}

.header-border,
.content-border {
  border-left: 1px solid $grey-10;
  border-right: 1px solid $grey-10;
}
.header-border {
  border-top: 1px solid $grey-10;
  border-bottom: 1px solid $grey-17;
}

.content-border {
  border-top: none;
  border-bottom: 1px solid $grey-10;
}

.description {
  color: $grey-30;
}

.stage-info {
  color: $grey-25;
  font-size: 10px;
  margin-left: -15px;
}

.blob-bg {
  background-image: url('~@/assets/images/SparkFunnels/Funnels/Create/blob_shape_top.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media only screen and (min-width: $screen-bs-sm) {
  #select-btn {
    width: auto !important;
  }
}

[id^='collapse'] {
  border-top: 1px solid $grey-17;
}

.stages-list {
  max-width: 200px;

  li p {
    color: $grey-30;
  }
}

.badge {
  background-color: $purple-dark;
  font-size: 12px;
}
</style>
