<template>
  <h5 v-if="!emailsFromOpenedStage || !emailsFromOpenedStage.length" class="without-email lh-150">
    {{ $t(`${prefix}.whitout_email`) }}
  </h5>
  <div v-else>
    <div class="d-flex pb-2">
      <hs-icon icon="envelope" class="title-icon mr-3" />
      <h6 class="title lh-150 text-uppercase font-weight-bold">{{ $t(`${prefix}.title`) }}</h6>
    </div>

    <div
      class="email-template d-flex flex-column pt-3"
      v-for="(emailTemplate, index) in emailsFromOpenedStage"
      :key="`email-template-${index}`"
    >
      <div class="d-flex align-items-center">
        <span class="lh-150 flex-1 d-flex flex-row text-truncate text-dark font-weight-bold">
          <span
            class="d-block font-weight-normal text-truncate"
            v-html="$t(`${prefix}.subject`, { subject: emailTemplate.settings.subject })"
          />
        </span>
      </div>
      <div class="send-moment d-flex align-items-center mb-3">
        <hs-icon icon="clock" class="mr-2 lh-150" />
        <span class="moment lh-150">{{ getMomentToSendText(emailTemplate, openedStage.stageType) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailTemplateMixin from '@/sparkfunnels/mixins/EmailTemplate';

export default {
  mixins: [EmailTemplateMixin],
  computed: {
    ...mapGetters('template', ['openedStage', 'emailsFromOpenedStage']),
    prefix: () => 'sparkfunnels.templates.create.sortable_collapse.emails',
  },
};
</script>

<style lang="scss" scoped>
.without-email,
.send-moment {
  color: $grey-30;
}

.title-icon {
  color: $grey-32;
}
.title {
  color: $grey-25;
}

.email-template {
  &:not(:last-child) {
    border-bottom: 1px solid $grey-17;
  }
  @media only screen and (min-width: $screen-bs-md) {
    border-color: $grey-20;
  }
}
</style>
