<template>
  <section class="d-flex flex-column pb-5">
    <div class="d-flex mb-4">
      <hs-icon icon="envelope" class="mr-2" />
      <h6 class="title">{{ $t(`${prefix}.title`) }}</h6>
    </div>
    <template v-if="emails && emails.length">
      <div v-for="email in emails" :key="email.id" class="d-flex flex-column">
        <div class="d-flex">
          <div class="flex-1 mr-5 text-truncate">
            <p class="text-truncate" v-html="$t(`${prefix}.subject`, { subject: email.settings.subject })" />
            <p class="moment-to-send flex">
              <hs-icon icon="paper" />
              {{ getMomentToSendText(email) }}
            </p>
          </div>
          <div>
            <hs-button
              variant="link"
              id="delete-email"
              class="px-0 text-danger font-weight-normal"
              @click="showDeleteEmailModal(email)"
            >
              <hs-icon icon="trash-alt" size="17" />
            </hs-button>
            <hs-button
              variant="link"
              id="edit-email"
              class="px-0 text-dark font-weight-normal ml-3"
              @click="showEditEmailModal(email)"
            >
              <hs-icon icon="pencil" size="17" />
            </hs-button>
          </div>
        </div>
        <hr class="divider w-100 my-4" />
      </div>
    </template>
    <p v-else class="without-emails align-self-center">{{ $t(`${prefix}.whitout_email`) }}</p>
    <hs-button id="create-email" variant="link" class="text-primary" @click="showCreateEmailModal">
      <hs-icon icon="plus" />
      {{ $t(`${prefix}.create`) }}
    </hs-button>
    <hsEditEmailModal
      :id="editModalId"
      :email="emailTemplateToEdit"
      :stage="stage"
      @create="createEmail"
      @edit="editEmail"
      :confirmNotify="false"
    />
    <hsConfirmModal
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t(`${prefix}.confirm_modal.title`)"
      :description="$t(`${prefix}.confirm_modal.description`)"
      :ok-title="$t(`${prefix}.confirm_modal.ok_title`)"
      :cancel-title="$t(`${prefix}.confirm_modal.cancel_title`)"
      :id="deleteModalId"
      @ok="deleteEmail({ stageId: stage.id, email: emailTemplateToDelete })"
    />
  </section>
</template>

<script>
import { hsConfirmModal } from '@/components';
import EmailTemplateHelper, { TriggerType } from '@/sparkfunnels/helpers/emailTemplate';
import { format } from '@/shared/helpers/dates';
import { mapGetters, mapMutations } from 'vuex';
import hsEditEmailModal from '@/sparkfunnels/components/EditEmailModal';

export default {
  components: {
    hsEditEmailModal,
    hsConfirmModal,
  },
  data() {
    return {
      emailTemplateToEdit: null,
      emailTemplateToDelete: null,
    };
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('template', ['emailsByStage']),
    emails() {
      return this.emailsByStage(this.stage.id);
    },
    editModalId() {
      return 'edit_' + this._uid.toString();
    },
    deleteModalId() {
      return 'delete_' + this._uid.toString();
    },
    prefix: () => 'sparkfunnels.templates.create.sortable_collapse.emails',
  },
  methods: {
    ...mapMutations('template', ['addEmail', 'updateEmail', 'deleteEmail']),
    showCreateEmailModal() {
      this.emailTemplateToEdit = {
        days: 0,
        hours: 0,
        minutes: 0,
        send_at: null,
        settings: { body: '', subject: '' },
        status: null,
        created_by_user: true,
      };
      this.$bvModal.show(this.editModalId);
    },
    showEditEmailModal(email) {
      this.emailTemplateToEdit = email;
      this.$bvModal.show(this.editModalId);
    },
    showDeleteEmailModal(email) {
      this.emailTemplateToDelete = email;
      this.$bvModal.show(this.deleteModalId);
    },
    createEmail(email) {
      this.addEmail({ stageId: this.stage.id, email });
    },
    editEmail(email) {
      this.updateEmail({ stageId: this.stage.id, email });
    },
    getMomentToSendText(emailTemplate) {
      const trigger = EmailTemplateHelper.getTriggerType(emailTemplate);
      if (trigger === TriggerType.IMMEDIATE) {
        return (
          this.$t(`${this.prefix}.trigger.immediate`) +
          this.$t(`${this.prefix}.status.${emailTemplate.status}.${this.stage.stageType}`)
        );
      }

      if (trigger === TriggerType.SPECIFIC_DATE) {
        const date = format(emailTemplate.send_at, `${this.$t('date.format')} HH:mm`);
        return this.$t(`${this.prefix}.trigger.specific`, { date });
      }

      return (
        this.$t(`${this.prefix}.trigger.after`, {
          days: emailTemplate.days,
          hours: emailTemplate.hours,
          minutes: emailTemplate.minutes,
        }) + this.$t(`${this.prefix}.status.${emailTemplate.status}.${this.stage.stageType}`)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title,
.without-emails {
  color: $grey-25;
}

.moment-to-send {
  color: $grey-30;
}

.divider {
  width: 1px;
  background: $grey-20;
}
</style>
