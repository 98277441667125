<template>
  <div class="py-4">
    <hs-group class="mb-4" :label="$t(`${prefix}.name.label`)" :label-for="`name-${stageId}`">
      <hs-input
        :id="`name-${stageId}`"
        v-model="form.name"
        :state="!$v.form.name.$error ? null : false"
        @blur="$v.form.name.$touch()"
        @change="setStageName({ stageId, name: form.name })"
        :placeholder="$t(`${prefix}.name.placeholder`)"
      />
      <hs-form-invalid-feedback :state="!$v.form.name.$error">
        <span class="font-size-xs" v-if="!$v.form.name.required">
          {{ $t(`${prefix}.name.validations.required`) }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <hs-group
      class="mb-4"
      :label="$t(`${prefix}.objective.label`)"
      :label-for="`objective-${stageId}`"
      :description="!$v.form.objective.$error ? $t(`${prefix}.objective.hint`, { max: 140 }) : undefined"
    >
      <b-form-textarea
        :id="`objective-${stageId}`"
        v-model="form.objective"
        rows="4"
        :state="!$v.form.objective.$error ? null : false"
        @blur="$v.form.objective.$touch()"
        @change="setStageObjective({ stageId, objective: form.objective })"
        :placeholder="$t(`${prefix}.objective.placeholder`)"
      />
      <hs-form-invalid-feedback :state="!$v.form.objective.$error">
        <span class="font-size-xs" v-if="!$v.form.objective.required">
          {{ $t(`${prefix}.objective.validations.required`) }}
        </span>
        <span class="font-size-xs" v-else-if="!$v.form.objective.maxLength">
          {{ $t(`${prefix}.objective.validations.maxLength`) }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <hs-group class="mb-4" :label="$t(`${prefix}.page_theme_id.label`)" :label-for="`page-theme-id-${stageId}`">
      <hs-input
        :id="`page-theme-id-${stageId}`"
        v-model="form.pageThemeId"
        @change="setStagePageThemeId({ stageId: stageId, pageThemeId: form.pageThemeId })"
        :placeholder="$t(`${prefix}.page_theme_id.placeholder`)"
      />
    </hs-group>
  </div>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    stageId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('template', ['stageById']),
    prefix: () => 'sparkfunnels.templates.create.sortable_collapse.stage',
    stage() {
      return this.stageById(this.stageId);
    },
  },
  data() {
    return {
      form: {
        name: null,
        objective: null,
        pageThemeId: null,
      },
    };
  },
  watch: {
    stageId() {
      this.updateValues();
    },
  },
  validations: {
    form: {
      name: { required },
      objective: { required, maxLength: maxLength(140) },
    },
  },
  methods: {
    ...mapActions('template', ['setStagePageThemeId']),
    ...mapMutations('template', ['setStageName', 'setStageObjective']),
    updateValues() {
      this.form.name = this.stage.name;
      this.form.objective = this.stage.objective;
      this.form.pageThemeId = this.stage.pageThemeId;
    },
  },
  mounted() {
    this.updateValues();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .form-group {
  > label,
  legend {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
