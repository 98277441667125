<template>
  <div class="tlw-flex tlw-flex-wrap tlw-items-start tlw-w-full tlw-content-center tlw-mb-12 tlw-justify-between">
    <div v-for="option in options" :key="option[indexProp]" class="checkbox-objective">
      <button
        class="checkbox-roundend tlw-flex tlw-mb-2 tlw-relative tlw-content-center tlw-items-center"
        :class="[{ 'active-rounded-checkbox': isSelected(option) }]"
        @click="() => toggleFromButton(option[indexProp])"
      >
        <hs-icon :icon="option.icon" size="30" />
        <input
          :ref="`checkbox_${option[indexProp]}`"
          type="checkbox"
          class="tlw-absolute"
          :checked="isSelected(option)"
          @click="onToggleOption(option)"
        />
      </button>
      <p class="tlw-text-center rounded-text-button">
        {{ $t(`sparkfunnels.funnels.create.steps.objective_selection.objectives.${option[indexProp]}`) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoundedCheckbox',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    indexProp: {
      type: String,
      default: 'id',
    },
    max: {
      type: Number,
      default: 2,
    },
  },
  methods: {
    toggleFromButton(ref) {
      this.$refs[`checkbox_${ref}`][0].click();
    },
    isSelected(option) {
      return this.value.find(el => el === option[this.indexProp]);
    },
    onSelected(option, value, max) {
      const { indexProp } = this;
      return value.slice(Number(value.length == max), max).concat([option[indexProp]]);
    },
    onDeselected(option) {
      return this.value.filter(value => value !== option[this.indexProp]);
    },
    onToggleOption(option) {
      const apply = this.isSelected(option) ? this.onDeselected : this.onSelected;
      this.$emit('input', apply(option, this.value, this.max));
    },
  },
};
</script>

<style lang="scss" scoped>
$objective-size: 90px;
.checkbox-objective {
  max-width: $objective-size;
  margin: 0 12px;

  > .active-rounded-checkbox {
    border: 2px solid $cyan !important;
  }

  .rounded-text-button {
    margin-top: 0.8rem;
    font-size: 0.845rem;
    margin-bottom: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .checkbox-roundend {
    width: $objective-size;
    height: $objective-size;
    display: block;
    cursor: pointer;
    border-radius: 50%;
    background: $light-blue;
    border: 1px solid #dee2e6;
    margin: 0 auto;

    &:hover {
      border: 0;
      outline: none;
    }

    &:active {
      border: 0;
      outline: none;
    }

    &:focus {
      border: 0;
      outline: none;
    }

    input {
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      right: 0.5rem;
      border-radius: 3px;
      background-color: #ffffff;
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      border: 1px solid var(--color-gray-200);

      &:hover {
        border: 2px solid #2cc3cc;
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        width: 1rem;
        height: 1rem;
        display: block;
        position: absolute;
        border-radius: 3px;
        background-color: transparent;
      }

      &:after {
        content: '';
        background: transparent;
        border: 0;
      }

      &:checked {
        border: 0 !important;

        &:before {
          background: #2cc3cc;
        }

        &:after {
          content: '';
          display: block;
          background-color: transparent;
          width: 4px;
          height: 8px;
          border: solid white;
          border-width: 0 1px 1px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 0.18rem;
          left: 0.4rem;
        }
      }
    }

    > .selected {
      background-color: #f1fbfc !important;
      border: 2px solid #2cc3cc !important;
    }
  }
}
</style>
